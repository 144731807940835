import axios from 'axios'
import { BASE_URL, API_KEY } from './networkConstants';
import { CLIENT_ID, CLIENT_API_KEY } from './wearediscountsConstants';
import { ReactGAEvent } from "../views/Helper/Helper";
import { AuthService } from "../components/OpenId/Service/AuthService";
import { ANONYMOUS_CUSTOMER_IDENTIFIER, ANONYMOUS_CUSTOMER_JOURNEY_IDENTIFIER, INCENTIVE_BANNER_SHOWN } from '../constants';

var mixpanel = require('mixpanel-browser');

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            let result = c.substring(name.length, c.length);
            return result === 'null' ? null : result;
        }
    }
    return null;
}

const request = function (options) {
    const user = JSON.parse(localStorage.getItem('userAuthenticated'));
    const token = user !== null ? user.access_token : '';
    const customerIdentifier = user !== null ? user.profile.sub : '';
    const anonymousCustomerIdentifier = getCookie(ANONYMOUS_CUSTOMER_IDENTIFIER);
    const anonymousCustomerJourneyIdentifier = getCookie(ANONYMOUS_CUSTOMER_JOURNEY_IDENTIFIER);

    ReactGAEvent({
        category: options.url,
        action: "request",
        label: customerIdentifier
    });

    let resultFetched = 0;

    let requestHeaders = {
        apiKey: API_KEY,
        customerIdentifier: user !== null ? customerIdentifier : anonymousCustomerIdentifier,
        Authorization: 'Bearer ' + token,
    };

    if (user === null) {
        requestHeaders = Object.assign({}, requestHeaders,
            anonymousCustomerJourneyIdentifier != null && anonymousCustomerJourneyIdentifier !== 'null'
                ? { journeyIdentifier: anonymousCustomerJourneyIdentifier }
                : {}
        );
    }

    if (!customerIdentifier) {
        requestHeaders = {
            ...requestHeaders,
            Name: "Merchant-Iframe",
            ClientId: "ef736a2c-eb2a-4090-b76a-c60516850d61",
            ClientApiKey: "ZWY3MzZhMmMtZWIyYS00MDkwLWI3NmEtYzYwNTE2ODUwZDYx",
            ClientLoginKey: "NTIzMTEzYzAtZjMyMy00NmRiLWIzZTUtODg2ZDgwYTM0Njc1",
        };
    }

    const client = axios.create({
        baseURL: BASE_URL,
        // auth: {  },
        timeout: 180000,
        headers: localStorage.appType !== 'WeAreDiscounts'
            ? requestHeaders
            : {
                clientId: CLIENT_ID,
                clientApiKey: CLIENT_API_KEY,
                customerIdentifier: customerIdentifier,
                Authorization: 'Bearer ' + token
            }
    });

    const onSuccess = function (response) {
        resultFetched = resultFetched + 1;
        if (resultFetched === 1)
            return response.data;
    }

    const onError = function (error) {
        // if unauthenticated-> mapdispatch to MainPage -> navigate to login..
        console.error('Request Failed:', error.config);

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);
            if (error.response.status === 401) {
                const authService = new AuthService();
                authService.logout();
                mixpanel.reset();
                localStorage.removeItem("userAuthenticated");
                localStorage.removeItem("userSimple");
                localStorage.removeItem("broadbandQuotes");
                localStorage.removeItem("energyQuotes");
                localStorage.removeItem(INCENTIVE_BANNER_SHOWN);
                window.location.href = "/mobileapp";
                return;
            }
        } else console.error('Error Message:', error.message);

        return Promise.reject(error.response || error.message);
    }

    return client(options)
        .then(onSuccess)
        .catch(onError);
}

export default request;
