import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Dialog from "@mui/material/Dialog";

import classes from "./Modal.module.css";

import { useStyles } from "./AptapDialogStyles";

function AptapDialog(props) {
  const styles = useStyles();
  const { onClose, open, ...rest } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      maxWidth={props.maxWidth}
      onClose={handleClose}
      aria-labelledby="simple-dialog"
      open={open}
      classes={{ paper: styles.paperStyle }}
      {...rest}
    >
      <div
        className={cx(classes.Modal, props.className)}
        style={{
          transform: "translateY(0)",
          opacity: "1",
          borderRadius: "15px"
        }}
      >
        {props.children}
      </div>
    </Dialog>
  );
}

AptapDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default AptapDialog;
