import React, { useEffect, useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import ReactGA from "react-ga";
import { AptapMainContext } from "./Context";
import { ApolloProvider } from "@apollo/client";
import client from "./views/AptapLanding/utils/apolloClient";
import updateWindowZoom from "./helpers/updateWindowZoom";

function App(props) {
  const aptapMainContext = useContext(AptapMainContext);

  useEffect(() => {
    window.addEventListener("resize", updateWindowZoom);
    updateWindowZoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateWindowZoom();
  }, [window.location.pathname]);

  if (
    window.location.hostname === "creation.aptap.co.uk" &&
    !window.location.pathname.includes("/mpredirect")
  )
    window.location.href = "https://www.aptap.co.uk";

  const setPathname = (pathname) => {
    localStorage.setItem("newpathname", pathname);
  };

  if (aptapMainContext.browserType.isIE) {
    props.history.push("/unsupported.html");
    props.history.go(0);
  } else {
    props.history.listen((location) => {
      // Not tracking this on MixPanel since only captures / and /login-page, which are already
      // tracked ("Visited page", {"Component": "DashboardMain"} and "Successful Login", {"Component": "LoginPage"})
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
  }

  if (
    window.location.href.indexOf("www.aptap") < 0 ||
    window.location.href.indexOf("aptapapp") > 0
  ) {
    if (
      window.location.hostname !== "barclays.aptap.co.uk" &&
      window.location.hostname !== "l.aptap.co.uk" &&
      window.location.hostname !== "tsb.aptap.co.uk" &&
      window.location.hostname !== "sprive.aptap.co.uk" &&
      window.location.hostname !== "creation.aptap.co.uk" &&
      window.location.hostname !== "h.aptap.co.uk" &&
      window.location.hostname !== "barclays.sandbox.aptapapp.co.uk" &&
      window.location.hostname !== "l.sandbox.aptapapp.co.uk" &&
      window.location.hostname !== "tsb.sandbox.aptapapp.co.uk" &&
      window.location.hostname !== "sprive.sandbox.aptapapp.co.uk" &&
      window.location.hostname !== "creation.sandbox.aptapapp.co.uk" &&
      window.location.hostname !== "switch.sandbox.aptapapp.co.uk" &&
      window.location.hostname !== "h.sandbox.aptapapp.co.uk" &&
      window.location.hostname !== "frontend.sandbox.aptapapp.co.uk"
    ) {
      window.location.href =
        "https://www.aptap.co.uk" +
        props.history.location.pathname +
        props.history.location.search;
    }
  }

  var user = JSON.parse(localStorage.getItem("userAuthenticated"));

  var fromAppType = localStorage.getItem("fromAppType");

  if (
    window.location.href.indexOf("/managemybills") > -1 ||
    (user && user.profile && user.profile.nickname === "APTEXT_67238c17") ||
    fromAppType === "Network"
  ) {
    localStorage.appType = "Network";
  } else {
    localStorage.appType = "apTap";
  }
  localStorage.removeItem("fromAppType");

  var logoutAppType = localStorage.getItem("logoutAppType");
  if (logoutAppType === "Network") {
    window.location.href = "/managemybills/login-page";
  }

  localStorage.removeItem("logoutAppType");
  return (
    <ApolloProvider client={client}>
      <BrowserRouter history={props.history}>
        <Routes setPathname={setPathname} />
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
