import request from '../network/request';

function refer(code, emails) {
    return request({
        url: `/generic/refer?code=${code}&emails=${emails}`,
        method: 'GET'
    });
}


function checkGeolocationInfo(data) {
    return request({
        url: `/generic/checkGeolocationInfo`,
        method: 'POST',
        data
    });
}

function preExternalSign(data) {
    return request({
        url: `/generic/PrepExternalSign`,
        method: 'POST',
        data
    });
}

const GenericService = { refer, checkGeolocationInfo, preExternalSign }
export default GenericService
