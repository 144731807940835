import ReactGA from 'react-ga';
import axios from 'axios'
import { useNavigate } from 'react-router';
import GenericService from "../../services/genericService";
import { IP_GEO_URL } from "../../network/networkConstants";
import { loginStart } from "../../js/actions";

export const checkCustomerStatus = (props) => {
    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem('userAuthenticated'));
    if (user === null || new Date() > new Date(user.expires_at * 1000)) {
        debugger;
        if (localStorage.appType !== "WeAreDiscounts") {
            if (props.location.pathname === "/banklogin-page") {
                debugger;
                axios.get(`${IP_GEO_URL}`).then(response => {
                    if (response && response.data && response.data.ip_address) {
                        checkCustomerGeolocationInfo(response.data.ip_address);
                    }
                    else {
                        navigate('/');
                    }
                }).catch(function (rej) {
                    console.log("IP_GEO_URL failed");
                    navigate('/');
                });
            }
            else {
                navigate('/');
            }
        }
        else {
            navigate('/wearediscounts');
        }
    }

    const checkCustomerGeolocationInfo = (ipaddress) => {
        var request = { information: ipaddress };
        GenericService.checkGeolocationInfo(request).then(res => {
            if (res && res.Data) {
                const loginpayload = {
                    loginObj: {
                        username: res.Data,
                        password: "",
                        rememberMe: false,
                        started: true
                    }
                };
                loginStart(loginpayload);
            }
            else {
                navigate('/');
            }
        }).catch(function (rej) {
            console.log("checkGeolocationInfo failed");
            navigate('/');
        });
    }
}

export const ReactGAEvent = (props) => {
    ReactGA.event({
        category: props.category,
        action: props.action,
        label: props.label
    });
}

export const ReactGAPage = (props) => {
    ReactGA.set({ page: props.pathname }); // Update the user's current page
    ReactGA.pageview(props.pathname);
}
